import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  Button,
  IconButton,
  Divider,
  Paper,
  Box,
  Switch,
  Select,
  MenuItem,
  Grid,
  ButtonGroup,
  List,
  ListItem,
  ListItemText,
  InputLabel,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import ArticleIcon from '@mui/icons-material/Article';
import Fab from '@mui/material/Fab';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import MenuIcon from '@mui/icons-material/Menu';
import DirectionsIcon from '@mui/icons-material/Directions';
import { templatesActions } from '../../../../application/actions/template';

import CreateForms from '../forms';

import { TemplatesService } from '../../../../infrastructure/services/template';

function Templates() {
  const dispatch = useDispatch();

  const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
  };

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#1976d2',
      },
    },
  });

  /* VARIABLES DE APLICACION */
  const {
    create, update, delete: deleteTemplate, get: getTemlete, verify: verifyTemplate,
  } = useSelector((store) => store.bazooit.template);
  const { workflows } = useSelector((store) => store.bazooit.workflowStorage);
  const [flujoSelcionado, setFlujoSelcionado] = useState({ name: 'Selecione un Flujo' });
  const [fileTemporal, setFileTemporal] = useState({});
  const [enabledForm, setEnabledForm] = useState(false);

  /* VARIABLES DE COMPONENTE */
  const [intefazEtapa] = useState([
    { id: 1, titulo: 'Crear Plantilla' },
    { id: 2, titulo: 'Editar Plantilla' },
    { id: 3, titulo: 'Eliminar Plantilla' },
    { id: 4, titulo: 'Gestionar registro de Plantillas de Documentos' },
  ]);
  const [posisicoUsuario, setPosicionUsaurio] = useState({ valor: 1 });
  const [verModal, setVerModal] = useState(false);
  const [formulario] = useState([{
    title: 'Nombre Formulario',
    groups: [
      {
        title: 'Datos de plantilla',
        fields: [
          {
            id: 'name',
            label: 'Nombre de plantilla',
            kind: 'text',
            required: true,
          },
          {
            id: 'file_1',
            label: 'Archivo 1',
            kind: 'file',
            required: true,
          },
        ],
      },
    ],
  }]);

  const [plantillaSelecionada, setPlantillaSelecionada] = useState(null);
  const [buscar, setBuscar] = useState('');

  useEffect(() => {
    dispatch(TemplatesService.activeGetTemplate({ id: flujoSelcionado.id }));
  }, [flujoSelcionado]);

  // MAESTROS
  const handleVerCrear = () => {
    setVerModal(true);
    setPosicionUsaurio({ valor: 1 });
  };

  const handleCrear = () => {
    const data = fileTemporal;

    if (data.file_1 !== undefined) {
      dispatch(TemplatesService.activeCreateTemplate(
        {
          cards:
          {
            workflowId: flujoSelcionado.id,
            fields: JSON.stringify(verifyTemplate.fields),
            ...data,
          },
        },
      ));
    }
  };

  useEffect(() => {
    if (!create.conexion && create.result) {
      dispatch(TemplatesService.activeGetTemplate({ id: flujoSelcionado.id }));
      setVerModal(false);
    }
  }, [create]);

  useEffect(() => {
    if (!update.conexion && update.result) {
      dispatch(TemplatesService.activeGetTemplate({ id: flujoSelcionado.id }));
      setVerModal(false);
    }
  }, [update]);

  useEffect(() => {
    if (!deleteTemplate.conexion && deleteTemplate.result) {
      dispatch(TemplatesService.activeGetTemplate({ id: flujoSelcionado.id }));
      setVerModal(false);
    }
  }, [deleteTemplate]);

  useEffect(() => {
    if (!verifyTemplate.conexion) {
      dispatch(templatesActions.createTemplate({
        result: false,
        message: '',
        conexion: false,
        error: false,
      }));
    }
  }, [verifyTemplate]);

  const handleEliminar = () => {
    dispatch(
      TemplatesService.activeDeleteTemplate(
        {
          workflowId: flujoSelcionado.id,
          templateId: plantillaSelecionada.id,
        },
      ),
    );
    setVerModal(false);
  };

  const handleAbandonar = () => {
    dispatch(templatesActions.verifyTemplate({
      result: false,
      message: '',
      conexion: false,
      error: false,
      fields: [],
    }));
    dispatch(templatesActions.createTemplate({
      result: false,
      message: '',
      conexion: false,
      error: false,
    }));
    setFileTemporal({});
    setVerModal(false);
  };

  const handleClickChek = (item) => {
    dispatch(
      TemplatesService.activeupdateSatatusTemplate(
        {
          workflowId: flujoSelcionado.id,
          templateId: item.id,
        },
      ),
    );
  };

  const handleVerEliminar = (item) => {
    setPlantillaSelecionada({ ...item });
    setVerModal(true);
    setPosicionUsaurio({ valor: 3 });
  };

  // FIN MAESTROS

  /// CREAR UNA PLANTILLA////////////////////////
  /// ///////////////////////////////////////////
  const [idFlujoEtapa] = useState(null);
  const [nombrePlantilla] = useState('');
  const [plantilla] = useState('');

  const [requestCreacion, setRequestCreacion] = useState({
    id_flujo_etapa: 0,
    nombre: '',
    contenido: '',
  });

  useEffect(() => {
    setRequestCreacion({
      ...requestCreacion,
      id_flujo_etapa: idFlujoEtapa,
      nombre: nombrePlantilla,
      contenido: plantilla,
    });
  }, [idFlujoEtapa, nombrePlantilla, plantilla]);

  const getFields = (get) => {
    if (get.length > 0) {
      let data = {};
      for (const a in get[0].groups) {
        for (const b in get[0].groups[a].fields) {
          if (get[0].groups[a].fields[b].kind !== 'file') {
            data = { ...data, [get[0].groups[a].fields[b].id]: get[0].groups[a].fields[b].value };
          } else {
            data = { ...data, [get[0].groups[a].fields[b].id]: get[0].groups[a].fields[b].file };
          }
        }
      }

      if (data.file_1 !== undefined) {
        if (fileTemporal.file_1 !== undefined) {
          if (data.file_1.name !== fileTemporal.file_1.name) {
            setFileTemporal(data);
            if (handlerDisambled) dispatch(TemplatesService.activeVerifyTemplate({ cards: { workflowId: flujoSelcionado.id, ...data } }));
          }
        } else {
          setFileTemporal(data);
          if (handlerDisambled) dispatch(TemplatesService.activeVerifyTemplate({ cards: { workflowId: flujoSelcionado.id, ...data } }));
        }
      }
    }
  };

  const getEnabledForm = (flag) => {
    setEnabledForm(flag);
  };

  const handlerDisambled = () => {
    let status = false;
    if (verifyTemplate.conexion || create.conexion) {
      status = true;
    } else if (!verifyTemplate.result || !verifyTemplate.fields.length > 0 || !enabledForm || create.error) {
      status = true;
    }
    return status;
  };

  /*   */

  const barSearch = () => {
    const ui = (
      <Paper
        sx={{ width: '100%' }}
      >
        <Grid className="search" item container>
          <Grid xs={12} sm={6} md={4} sx={{ borderBottom: 'solid 1px #00000020' }}>
            <Typography sx={{ p: 0 }} component="span">
              <FormControl className="no-boder-select no-label" fullWidth size="small">
                <InputLabel id="select-flujo-document-label">{flujoSelcionado.id ? '' : flujoSelcionado.name}</InputLabel>
                <Select
                  labelId="select-flujo-document-label"
                  id="select-flujo-document"
                  label={flujoSelcionado.name}
                  onChange={(e) => { setFlujoSelcionado(e.target.value); }}
                  fullWidth
                  sx={{ p: 0.3 }}
                >
                  {workflows.length > 0 && workflows.map((item, index) => (

                    <MenuItem key={index.id} value={item}>{item.name}</MenuItem>

                  ))}
                </Select>
              </FormControl>
            </Typography>
          </Grid>
          <Grid xs={12} sm={6} md={8}>
            <Box
              component="form"
              sx={{
                display: 'flex', alignItems: 'right', width: '100%',
              }}
            >
              <>
                <Divider sx={{ height: 45 }} orientation="vertical" />
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Buscar en usuarios"
                  inputProps={{ 'aria-label': 'search google maps' }}
                  value={buscar}
                  onChange={(e) => setBuscar(e.target.value)}
                />
                <IconButton type="button" sx={{ p: '10px' }} aria-label="buscar">
                  <SearchIcon />
                </IconButton>
              </>
            </Box>
          </Grid>
        </Grid>
      </Paper>

    );

    return ui;
  };

  return (

    <>
      {/* AGREGAR NUEVO */}
      <Fab disabled={!flujoSelcionado.id} onClick={() => handleVerCrear()} sx={fabStyle} color="primary" aria-label="add">
        <AddIcon />
      </Fab>
      {/* MODAL LINK ARTEFACTO */}
      <Dialog disableEscapeKeyDown open={verModal} onClose={handleAbandonar} fullWidth={posisicoUsuario.valor === 4 ? 'xl' : 'sm'} maxWidth={posisicoUsuario.valor === 4 ? 'xl' : 'sm'}>
        <DialogTitle>{intefazEtapa[posisicoUsuario.valor - 1].titulo}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleAbandonar}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Divider />
        <DialogContent dividers>
          <Backdrop
            sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={verifyTemplate.conexion || create.conexion}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Typography variant="p" component="p">
            <Box sx={{ mb: 0, mt: 2 }}>
              {posisicoUsuario.valor === 1 && (
                <>
                  <Typography component="primary">
                    Indique el nombre de la plantilla y carguela,
                    al finalizar verique los campos que encontramos.
                  </Typography>
                  <Box sx={{ ml: -2, mr: -2 }}>
                    <CreateForms
                      fields={formulario}
                      getFields={getFields}
                      getEnabledForm={getEnabledForm}
                    />
                  </Box>
                  <br />
                  <Box sx={{ p: 0 }}>
                    {
                      verifyTemplate.result
                      && verifyTemplate.fields.length > 0 && (
                        <Alert sx={{ mb: 3 }} severity="success">
                          <AlertTitle>Variables encontradas en el documento</AlertTitle>
                          {verifyTemplate.fields.map((item) => (
                            <>
                              {item.replace('{', '').replace('}', '')}
                              <br />
                            </>

                          ))}
                        </Alert>
                      )
                    }

                    {
                      verifyTemplate.error
                      && verifyTemplate.message !== '' && (
                        <Alert sx={{ mb: 3 }} severity="error">
                          <AlertTitle>Error</AlertTitle>
                          {verifyTemplate.message}
                        </Alert>
                      )
                    }

                    {
                      create.error
                      && create.message !== '' && (
                        <Alert sx={{ mb: 3 }} severity="error">
                          <AlertTitle>Error</AlertTitle>
                          {create.message}
                        </Alert>
                      )
                    }

                  </Box>
                  <Alert severity="info">
                    <AlertTitle>Registro de Plantilla de Documentos</AlertTitle>
                    Aqui almacenaras tus Plantillas de documentos.
                    {' '}
                    <strong>
                      En base a estas plantillas podras generar documentso asociados a tus gestiones!
                    </strong>
                  </Alert>
                </>
              )}
              {posisicoUsuario.valor === 3 && (
                <>
                  ¿Seguro que deseas elimnaras el template
                    {' '}
                  <strong>
                    `` "
                    {plantillaSelecionada.name}
                    "
                  </strong>

                  ?
                  <br />
                  <br />
                  <Alert severity="warning">
                    <AlertTitle>Eliminar Registro de Plantilla de Documentos</AlertTitle>
                    Con esta acción eliminas de manera permanente el template del sistema.
                    {' '}
                    <strong>Esta acción es irreversible!</strong>
                  </Alert>
                </>

              )}
            </Box>
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ m: 1 }}>
          <Box sx={{ mb: 0, mt: 0 }}>
            <FormControl fullWidth>
              <ButtonGroup sx={{ pt: 0 }} fullWidth variant="contained">
                {posisicoUsuario.valor === 1 && (
                  <Button
                    disabled={handlerDisambled()}
                    color="success"
                    onClick={() => handleCrear()}
                  >
                    Ok, Crear
                  </Button>
                )}
                {posisicoUsuario.valor === 3 && (
                  <Button color="warning" onClick={() => handleEliminar()}>
                    Si, eliminar
                  </Button>
                )}
                <Button onClick={() => handleAbandonar()}>
                  Abandonar
                </Button>
              </ButtonGroup>
            </FormControl>
          </Box>
        </DialogActions>
      </Dialog>
      <Box bgcolor="#f1f1f1">
        <Stack sx={{ flexGrow: 1 }}>
          <ThemeProvider theme={darkTheme}>
            <AppBar position="static" color="primary">
              <Grid container spacing={10} sx={{ p: 2 }}>
                <Grid sx={{ p: 0 }} item xs={12}>
                  <Grid container sx={{ p: 0 }}>
                    <Grid sx={{ p: 0 }} item xs={12}>
                      <Typography sx={{ p: 0 }} component="strong">
                        <strong>Mis Plantillas de Documentos</strong>
                      </Typography>
                    </Grid>
                    <Grid sx={{ p: 0 }} item xs={12}>{' '}</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AppBar>
          </ThemeProvider>
        </Stack>
        <Grid container sx={{ p: 1 }} columns={12}>
          <Grid xs={12} sm={12} md={12}>
            <Grid sx={{ mb: 1 }}>
              {barSearch()}
            </Grid>
            {!flujoSelcionado.id && (
              <Alert severity="info">
                <AlertTitle>Selecione un Flujo</AlertTitle>
                Debes seleccionar un flujo al cual asignaras las plantillas de documentos.
                <br />
                {' '}
                <strong>
                  Es indispensable tener al menos un flujo para asociarlo a tus plantillas!
                </strong>
              </Alert>
            )}
            {flujoSelcionado.id && getTemlete.data && getTemlete.data.length === 0 && (
              <Alert severity="info">
                <AlertTitle>Sin registros de plantillas disponibles</AlertTitle>
                Actualmente no tienes planillas disponibles para mostrar y trabajar.
                {' '}
                <strong>Es indispensable tener al menos un flujo para asociarlo a tus plantillas!</strong>
              </Alert>
            )}
            <List sx={{ p: 0 }}>
              {getTemlete.data && getTemlete.data.filter((x) => x.name.toLowerCase().slice(0, buscar.length) === buscar.toLowerCase()).map((item) => (
                <>
                  <ListItem sx={{ p: 3 }} selected={!!(plantillaSelecionada !== null && plantillaSelecionada.id && plantillaSelecionada.id === item.id)} alignItems="flex-start">
                    <ListItemAvatar sx={{ pl: 3 }}>
                      <ArticleIcon alt="Documento" src="" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={(
                        <Typography
                          component="div"
                          color="text.primary"
                        >
                          {`${item.name}`}
                        </Typography>
                      )}
                    />
                    <Box sx={{ p: '10px' }}>
                      <Switch
                        checked={!item.isDisabled}
                        onClick={() => handleClickChek(item)}
                        size="small"
                        disabled={update.conexion || getTemlete.conexion}
                      />
                    </Box>
                    <Box>
                      <IconButton disabled={deleteTemplate.conexion || getTemlete.conexion} onClick={() => handleVerEliminar(item)} type="button" sx={{ p: '10px' }}>
                        <DeleteForeverIcon />
                      </IconButton>
                    </Box>
                  </ListItem>
                  <Divider />
                </>
              ))}
            </List>
          </Grid>
        </Grid>
      </Box>
    </>

  );
}

export default Templates;
