import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  Button,
  Divider,
  Box,
  ButtonGroup,
  Typography,
} from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CreateForms from '../forms';
import { cardsActionsSaga } from '../../../../infrastructure/services/cards';
import { cardsActions } from '../../../../application/actions/cards';

function CreateNewCards({
  handleClose,
  handleRefrescar,
  open,
}) {
  const dispatch = useDispatch();

  const { fields } = useSelector((store) => store.bazooit.cards.fieldsByColumnId);
  const { createCards } = useSelector((store) => store.bazooit.cards);
  const [newFields, setNewFields] = useState(null);
  const [enabledForm, setEnabledForm] = useState(null);
  const {
    nombreGrupoworkflowselecionado, idWorkflowGroupSelected, nameWorkflowSelected, idWorkflowSelected, idArtifactSelected, descriptionWorkflowSelected,
  } = useSelector((store) => store.bazooit.storageBrowser);
  const { workflows } = useSelector((store) => store.bazooit.workflowStorage);
  const send = false;

  const sendCreateCards = () => {
    let data = {};
    for (const x in newFields) {
      for (const a in newFields[x].groups) {
        for (const b in newFields[x].groups[a].fields) {
          if (newFields[x].groups[a].fields[b].kind !== 'file') {
            data = { ...data, [newFields[x].groups[a].fields[b].id]: newFields[x].groups[a].fields[b].value };
          } else {
            data = { ...data, [`FILEBAZOOIT_${newFields[x].groups[a].fields[b].id}`]: newFields[x].groups[a].fields[b].file };
          }
        }
      }
    }

    console.log(data);
    dispatch(cardsActionsSaga.activeCardsCreate({ cards: { workflowId: idWorkflowSelected, ...data } }));

    // dispatch(cardsActionsSaga.activeCardsCreate({ cards: { workflowId: idWorkflowSelected, columnProperty: id, data: newFields[x].groups } }));
  };

  const getFields = (get) => {
    setNewFields(get);
  };

  const getEnabledForm = (flag) => {
    console.log(flag);
    setEnabledForm(flag);
  };

  useEffect(() => {
    if (createCards.result) {
      handleRefrescar();
      dispatch(cardsActions.cardsCreate({ result: false }));
      handleClose();
    }
  }, [createCards]);

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle sx={{ lineHeight: 1.2 }}>
        <Typography sx={{ pb: 0, mb: 0 }} variant="p" component="p">
          {fields.map((item) => item.initialFields && item.name)}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ pl: 0, pr: 0, pt: 0 }}>
        <CreateForms
          fields={fields}
          getFields={getFields}
          getEnabledForm={getEnabledForm}
          idGroupShow={0}
        />
        <Box sx={{ pl: 3, pr: 3 }}>
          <Typography>
            <Box sx={{ mb: 0, mt: 2 }}>
              <FormControl fullWidth>
                <ButtonGroup sx={{ pt: 0, zIndex: 5 }} fullWidth variant="contained">
                  {!createCards.result
                    && (
                      <Button disabled={!createCards.connection ? !enabledForm : enabledForm} onClick={() => sendCreateCards()}>
                        Crear
                      </Button>
                    )}
                  <Button onClick={(e) => { handleClose(e); }}>
                    {createCards.result ? 'Listo! datos enviados' : 'Salir'}
                  </Button>
                </ButtonGroup>
              </FormControl>
            </Box>
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default CreateNewCards;
