import React, { useEffect, useState } from 'react';
import {
  TextField,
  IconButton,
  Divider,
  Paper,
  Box,
} from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { PropTypes } from 'prop-types';

export function FieldListImputIncrement({
  field, onChange, indexField, indexGroup, indexFormulario, customize,
}) {
  const [age, setAge] = useState('');
  const [listArrayValue, setListArrayValue] = useState([]);
  const [uiState] = useState(['crear', 'editar', 'eliminar']);

  useEffect(() => {
    if (field.value && field.value.length > 0) { setListArrayValue(field.value); }
  }, [field]);

  const onSetListArrayValue = (actividad, evento, index) => {
    const newListArrayValue = listArrayValue;
    switch (evento) {
      case uiState[0]:
        newListArrayValue.push(actividad);
        break;
      case uiState[1]:
        newListArrayValue[index] = actividad;
        break;
      case uiState[2]:
        newListArrayValue.splice(index, 1);
        break;
      default:
        break;
    }
    setListArrayValue(newListArrayValue);
    setAge(Math.floor(Math.random() * 10000));
  };

  useEffect(() => {
    onChange(indexField, listArrayValue, indexGroup, indexFormulario);
  }, [listArrayValue, age]);

  const listImputs = () => {
    const list = listArrayValue.length > 0 && listArrayValue.map((item, index) => (
      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 }, border: 0 }}>
        <TableCell sx={{ p: 0, pl: 1 }}>
          <TextField fullWidth size="small" id={`blokActividadNombre_${index}`} value={item} onChange={(e) => onSetListArrayValue(e.target.value, uiState[1], index)} variant="standard" />
        </TableCell>
        {/* DATOS DE FORMULARIO */}
        <TableCell sx={{ p: 0.5 }} align="right">
          <IconButton size="small" color="primary" aria-label="upload picture" component="label" onClick={() => onSetListArrayValue(null, uiState[2], index)}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
    return list;
  };
  return (
    <Box sx={{ p: 0, mt: 2 }} component={Paper}>

      <Box sx={{ p: 0 }}>
        <IconButton sx={{ mb: 0 }} CancelIcon color="primary" aria-label="upload picture" component="label" onClick={() => onSetListArrayValue('', uiState[0], 0)}>
          <AddIcon />
        </IconButton>
        <Typography sx={{ mb: 0 }} variant="small" component="small">
          agregar registros
        </Typography>
      </Box>
      <>
        <Divider />
        <Box sx={{ p: 1 }}>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ p: 0 }} align="center"><small>Registro</small></TableCell>
                  <TableCell sx={{ p: 0.5 }} align="right"><small>Elim.</small></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listImputs()}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    </Box>
  );
}

FieldListImputIncrement.propTypes = {
  field: PropTypes.objectOf.isRequired,
  onChange: PropTypes.func.isRequired,
  indexField: PropTypes.number.isRequired,
  indexGroup: PropTypes.number.isRequired,
  indexFormulario: PropTypes.number.isRequired,
  customize: PropTypes.objectOf.isRequired,
};

export default FieldListImputIncrement;
