import {
  spawn, call, put, takeLatest,
} from 'redux-saga/effects';
import { createAction } from '@reduxjs/toolkit';
import { cardsActions } from '../../application/actions/cards';
import { applicationActions } from '../../application/actions/application';

import api from '../api/index';

const apiRequest = api(process.env.REACT_APP_API_BAZOOIT);

export function* cardsGet(e) {
  yield put(applicationActions.applicationConnection({ connection: true }));
  yield put(cardsActions.cardsGet({ connection: true }));
  try {
    const { query } = e.payload;
    const url = `cards/${query}`;
    const response = yield call(
      apiRequest.get,
      url,
      // params
    );
    // Extraigo todos los datos
    const { success } = response;
    if (success) {
      const { data } = response;
      yield put(cardsActions.cardsGet({
        cards: data,
        result: true,
      }));
    } else {
      const { message } = response;
      yield put(applicationActions.aplicationErrorSet({ estatus: true, mensaje: message }));
    }
  } catch (err) {
    yield put(cardsActions.cardsGet({ error: true }));
    yield put(applicationActions.aplicationErrorSet({ estatus: true, mensaje: err }));
  } finally {
    yield put(applicationActions.applicationConnection({ connection: false }));
    yield put(cardsActions.cardsGet({ connection: false }));
  }
}

export function* cardsCreate(e) {
  yield put(applicationActions.applicationConnection({ connection: true }));
  yield put(cardsActions.cardsCreate({ connection: true }));
  try {
    const formdata = new FormData();
    const params = e.payload.cards;
    const keys = Object.keys(params);
    const fileIds = [];

    for (const k of keys) {
      if (typeof (params[k]) === 'string') {
        formdata.append(`${k}`, `${params[k]}`);
        // Necesitamos hacer un flag que indique que este parametro es un archivo y se cargo algun dato
      } else if (k.includes('FILEBAZOOIT_') && params[k] != null) {
        formdata.append('files', params[k]);
        fileIds.push(k.replace('FILEBAZOOIT_', ''));
      } else if (params[k] != null) {
        formdata.append(`${k}`, params[k]);
      }
    }

    if (fileIds.length > 0) formdata.append('fileIds', `${JSON.stringify(fileIds)}`);

    const url = 'cards/create';
    const response = yield call(
      apiRequest.postFormData,
      url,
      formdata,
    );
    // Extraigo todos los datos
    const { success } = response;
    if (success) {
      yield put(cardsActions.cardsCreate({
        result: true,
        connection: false,
      }));
    } else {
      const { message } = response;
      yield put(applicationActions.aplicationErrorSet({ estatus: true, mensaje: message }));
    }
  } catch (err) {

  } finally {
    yield put(applicationActions.applicationConnection({ connection: false }));
  }
}

export function* cardsUpdate(e) {
  yield put(applicationActions.applicationConnection({ connection: true }));

  try {
    const params = e.payload.changeColumnsCard;
    const url = `cards/${params.id}`;
    const response = yield call(
      apiRequest.put,
      url,
      params,
    );
    // Extraigo todos los datos
    const { success } = response;
    if (success) {
      yield put(cardsActions.cardsUpdate({
        result: true,
      }));
    } else {
      const { message } = response;
      yield put(applicationActions.aplicationErrorSet({ estatus: true, mensaje: message }));
    }
  } catch (err) {

  } finally {
    yield put(applicationActions.applicationConnection({ connection: false }));
  }
}

export function* fieldsUpdate(e) {
  yield put(applicationActions.applicationConnection({ connection: true }));
  yield put(cardsActions.fieldsUpdate({ connection: true }));
  try {
    const { id } = e.payload;
    const url = `columns/fieldsByColumnId?columnId=${id}`;
    const response = yield call(
      apiRequest.get,
      url,
      // params
    );
    // Extraigo todos los datos
    const { success } = response;
    if (success) {
      const { data } = response;
      yield put(cardsActions.fieldsUpdate({
        fields: data,
        result: true,
      }));
    } else {
      const { message } = response;
      yield put(applicationActions.aplicationErrorSet({ estatus: true, mensaje: message }));
    }
  } catch (err) {
    yield put(cardsActions.fieldsUpdate({ error: true }));
    yield put(applicationActions.aplicationErrorSet({ estatus: true, mensaje: err }));
  } finally {
    yield put(applicationActions.applicationConnection({ connection: false }));
    yield put(cardsActions.fieldsUpdate({ connection: false }));
  }
}

export function* cardsGetDetail(e) {
  yield put(applicationActions.applicationConnection({ connection: true }));
  yield put(cardsActions.cardsGetDetail({ connection: true }));
  try {
    const { query } = e.payload;
    const url = `cards/${query}`;
    const response = yield call(
      apiRequest.get,
      url,
      // params
    );
    // Extraigo todos los datos
    const { success } = response;
    if (success) {
      const { data } = response;
      yield put(cardsActions.cardsGetDetail({
        cardDetail: data,
        result: true,
        connection: false,
      }));
    } else {
      const { message } = response;
      yield put(applicationActions.aplicationErrorSet({ estatus: true, mensaje: message }));
    }
  } catch (err) {
    yield put(cardsActions.cardsGetDetail({ error: true }));
    yield put(applicationActions.aplicationErrorSet({ estatus: true, mensaje: err }));
  } finally {
    yield put(applicationActions.applicationConnection({ connection: false }));
    yield put(cardsActions.cardsGetDetail({ connection: false }));
  }
}

export function* cardsUpdateData(e) {
  yield put(applicationActions.applicationConnection({ connection: true }));
  // yield put(cardsActions.cardsCreate({ connection: true }));
  try {
    const formdata = new FormData();
    const params = e.payload.cards;
    const { idCard } = e.payload;
    const keys = Object.keys(params);
    const fileIds = [];

    for (const k of keys) {
      if (typeof (params[k]) === 'string') {
        formdata.append(`${k}`, `${params[k]}`);
        // Necesitamos hacer un flag que indique que este parametro es un archivo y se cargo algun dato
      } else if (k.includes('FILEBAZOOIT_') && params[k] != null) {
        formdata.append('files', params[k]);
        fileIds.push(k.replace('FILEBAZOOIT_', ''));
      } else if (params[k] !== null) {
        formdata.append(`${k}`, params[k]);
      }
    }

    if (fileIds.length > 0) formdata.append('fileIds', `${JSON.stringify(fileIds)}`);

    const url = `cards/${idCard}`;
    const response = yield call(
      apiRequest.patchFormData,
      url,
      formdata,
    );
    // Extraigo todos los datos
    const { success } = response;
    if (success) {
      yield put(cardsActions.cardsUpdate({
        result: true,
        connection: false,
      }));
    } else {
      const { message } = response;
      yield put(applicationActions.aplicationErrorSet({ estatus: true, mensaje: message }));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(applicationActions.applicationConnection({ connection: false }));
  }
}

export function* cardHasBeenUpdated(e) {
  let response = null;
  yield put(cardsActions.cardHasBeenUpdated({ connection: true, checking: true }));
  try {
    const { cardId, updatedAt } = e.payload;
    const url = `cards/cardHasBeenUpdated/${cardId}?updatedAt=${updatedAt}`;
    response = yield call(
      apiRequest.get,
      url,
      // params
    );
    // Extraigo todos los datos
    const { success } = response;
    if (success) {
      yield put(cardsActions.cardHasBeenUpdated({ success }));
    } else {
      const { message } = response;
      yield put(cardsActions.cardHasBeenUpdated({ success, message }));
    }
  } catch (erro) {
    const { success, message } = response;
    yield put(cardsActions.cardHasBeenUpdated({ success, message }));
  } finally {
    yield put(cardsActions.cardHasBeenUpdated({ connection: false }));
  }
}

export function* watchFetchCards() {
  yield takeLatest(cardsActionsSaga.activeCardsGet.type, cardsGet);
  yield takeLatest(cardsActionsSaga.activeCardsCreate.type, cardsCreate);
  yield takeLatest(cardsActionsSaga.activeCardsUpdate.type, cardsUpdate);
  yield takeLatest(cardsActionsSaga.activeFieldsUpdate.type, fieldsUpdate);
  yield takeLatest(cardsActionsSaga.activeCardsGetDetail.type, cardsGetDetail);
  yield takeLatest(cardsActionsSaga.activeCardsUpdateData.type, cardsUpdateData);
  yield takeLatest(cardsActionsSaga.activeCardHasBeenUpdated.type, cardHasBeenUpdated);
}

export default function* rootSaga() {
  yield spawn(watchFetchCards);
}

const activeCardsGet = createAction('cardsActions/activeCardsGet');
const activeCardsCreate = createAction('cardsActions/activeCardsCreate');
const activeCardsUpdate = createAction('cardsActions/activeCardsUpdate');
const activeFieldsUpdate = createAction('cardsActions/activeFieldsUpdate');
const activeCardsGetDetail = createAction('cardsActions/activeCardsGetDetail');
const activeCardsUpdateData = createAction('cardsActions/activeCardsUpdateData');
const activeCardHasBeenUpdated = createAction('cardsActions/activeCardHasBeenUpdated');

export const cardsActionsSaga = {
  activeCardsGet,
  activeCardsCreate,
  activeCardsUpdate,
  activeFieldsUpdate,
  activeCardsGetDetail,
  activeCardsUpdateData,
  activeCardHasBeenUpdated,
};
