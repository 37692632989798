import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  Button,
  IconButton,
  Divider,
  Paper,
  Box,
  Switch,
  Select,
  MenuItem,
  Grid,
  ButtonGroup,
  List,
  ListItem,
  ListItemText,
  InputLabel,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import ArticleIcon from '@mui/icons-material/Article';
import Fab from '@mui/material/Fab';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CreateForms from '../forms';

import { TemplatesService } from '../../../../infrastructure/services/template';
import { masterActions } from '../../../../application/actions/master';

import { MasterService } from '../../../../infrastructure/services/maestros';

function Masterdata() {
  const dispatch = useDispatch();

  const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
  };

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#1976d2',
      },
    },
  });

  /* VARIABLES DE APLICACION */

  const {
    create,
    disable: update,
    masterData,
    masterDataChildren,
  } = useSelector((store) => store.bazooit.master);

  const [flujoSelcionado, setFlujoSelcionado] = useState({ name: 'Selecione un Flujo' });
  const [fileTemporal, setFileTemporal] = useState({});
  const [enabledForm, setEnabledForm] = useState(false);

  /* VARIABLES DE COMPONENTE */
  const [intefazEtapa] = useState([
    { id: 1, titulo: 'Crear maestro' },
    { id: 2, titulo: 'Ver detalle maestro' },
    { id: 3, titulo: 'Eliminar maestro' },
    { id: 4, titulo: 'Gestionar registro de maestros' },
  ]);
  const [masterView, setMasterView] = useState('');
  const [posisicoUsuario, setPosicionUsaurio] = useState({ valor: 1 });
  const [verModal, setVerModal] = useState(false);
  const [formulario] = useState([{
    name: 'Nombre Formulario',
    groups: [
      {
        title: 'Datos del Maestro',
        fields: [
          {
            id: 'name',
            label: 'Nombre del registro maestro',
            kind: 'text',
            required: true,
          },
          {
            id: 'name',
            label: 'Nombre del registro maestro',
            kind: 'listInput',
            required: true,
          },
        ],
      },
    ],
  }]);

  const [plantillaSelecionada, setPlantillaSelecionada] = useState(null);
  const [buscar, setBuscar] = useState('');

  useEffect(() => {
    dispatch(MasterService.activeMasterData());
  }, []);

  // MAESTROS
  const handleVerCrear = () => {
    setVerModal(true);
    setPosicionUsaurio({ valor: 1 });
  };

  // REFRES
  useEffect(() => {
    if (!create.conexion && create.result) {
      dispatch(MasterService.activeMasterData());
      setVerModal(false);
    }
  }, [create]);

  useEffect(() => {
    if (!update.conexion && update.result) {
      dispatch(MasterService.activeMasterData());
      setVerModal(false);
    }
  }, [update]);

  /*   useEffect(() => {
    if (!deleteTemplate.conexion && deleteTemplate.result) {
      dispatch(MasterService.activeMasterData());
      setVerModal(false);
    }
  }, [deleteTemplate]); */

  useEffect(() => {
    dispatch(masterActions.putDisableMaster({ connection: false, result: false }));
  }, [masterData]);
  // REFRES

  // HANDLER
  const handleEliminar = () => {

  };

  const handleAbandonar = () => {
    setVerModal(false);
  };

  const handleClickChek = (item) => {
    console.log(item);
    const { id: masterDataId, kind } = item;
    dispatch(MasterService.activeMasterDisable({ masterDataId, kind }));
  };

  const handleVerEliminar = (item) => {
    setPlantillaSelecionada({ ...item });
    setVerModal(true);
    setPosicionUsaurio({ valor: 3 });
  };

  const handlerDetails = (item) => {
    const { id: masterDataId, name } = item;
    setMasterView(name);
    dispatch(MasterService.activeMasterDataChildren({ masterDataId }));
    setPosicionUsaurio({ valor: 2 });
    setVerModal(true);
  };
  const handlerDisambled = () => {
    let status = false;
    if (!enabledForm || create.conexion) {
      status = true;
    }
    return status;
  };

  const handleCrear = () => {
    dispatch(MasterService.activeMasterCreater({ masterData: fileTemporal }));
  };
  // HANDLER

  const getFields = (get) => {
    if (get.length > 0) {
      const name = get[0].groups[0].fields[0].value ? get[0].groups[0].fields[0].value : '';
      const children = get[0].groups[0].fields[1].value ? get[0].groups[0].fields[1].value : '';
      const newRegister = {
        name,
        kind: 'company',
        children,
      };
      setFileTemporal(newRegister);
    }
  };

  const getEnabledForm = (flag) => {
    setEnabledForm(flag);
  };

  return (

    <>
      {/* AGREGAR NUEVO */}
      <Fab onClick={() => handleVerCrear()} sx={fabStyle} color="primary" aria-label="add">
        <AddIcon />
      </Fab>
      {/* MODAL LINK ARTEFACTO */}
      <Dialog disableEscapeKeyDown open={verModal} onClose={handleAbandonar} fullWidth={posisicoUsuario.valor === 4 ? 'xl' : 'sm'} maxWidth={posisicoUsuario.valor === 4 ? 'xl' : 'sm'}>
        <DialogTitle>{intefazEtapa[posisicoUsuario.valor - 1].titulo}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleAbandonar}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Divider />
        <DialogContent dividers>
          <Backdrop
            sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={create.conexion}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Typography variant="p" component="p">
            <Box sx={{ mb: 0, mt: 2 }}>
              {posisicoUsuario.valor === 1 && (
                <>
                  <Typography component="primary">
                    Indique el nombre de el registro de maestro y agrega los registros.
                  </Typography>
                  <Box sx={{ ml: -2, mr: -2 }}>
                    <CreateForms
                      fields={formulario}
                      getFields={getFields}
                      getEnabledForm={getEnabledForm}
                    />
                  </Box>
                  <br />
                  <Alert severity="info">
                    <AlertTitle>Registro de Maestros</AlertTitle>
                    Aqui almacenaras tus registros o listas.
                    {' '}
                    <strong>
                      Estoas registos estaran disponibles en tus gestiones!
                    </strong>
                  </Alert>
                </>
              )}
              {posisicoUsuario.valor === 2 && (
                <>
                  <Typography component="div" variant="p">
                    Maestro:
                  </Typography>
                  <Typography component="primary" variant="h5">
                    {masterView}
                  </Typography>
                  <br />
                  <br />
                  <Box sx={{ p: 0, pb: 3 }}>
                    {
                      masterDataChildren.result
                      && masterDataChildren.data.rows.length > 0 && (
                        <Alert severity="success">
                          <AlertTitle>Contenido de maestros</AlertTitle>
                          {masterDataChildren.data.rows.map((item) => (
                            <>
                              {item.name}
                              <br />
                            </>

                          ))}
                        </Alert>
                      )
                    }
                  </Box>

                </>
              )}
              {posisicoUsuario.valor === 3 && (
                <>
                  ¿Seguro que deseas elimnaras el template
                    {' '}
                  <strong>
                    `` "
                    {plantillaSelecionada.name}
                    "
                  </strong>

                  ?
                  <br />
                  <br />
                  <Alert severity="warning">
                    <AlertTitle>Eliminar Registro de Plantilla de Documentos</AlertTitle>
                    Con esta acción eliminas de manera permanente el template del sistema.
                    {' '}
                    <strong>Esta acción es irreversible!</strong>
                  </Alert>
                </>

              )}
            </Box>
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ m: 1 }}>
          <Box sx={{ mb: 0, mt: 0 }}>
            <FormControl fullWidth>
              <ButtonGroup sx={{ pt: 0 }} fullWidth variant="contained">
                {posisicoUsuario.valor === 1 && (
                  <Button
                    disabled={handlerDisambled()}
                    color="success"
                    onClick={() => handleCrear()}
                  >
                    Ok, Crear
                  </Button>
                )}
                {posisicoUsuario.valor === 3 && (
                  <Button color="warning" onClick={() => handleEliminar()}>
                    Si, eliminar
                  </Button>
                )}
                <Button onClick={() => handleAbandonar()}>
                  {posisicoUsuario.valor === 2 ? 'Salir' : 'Abandonar'}
                </Button>
              </ButtonGroup>
            </FormControl>
          </Box>
        </DialogActions>
      </Dialog>
      <Box bgcolor="#f1f1f1">
        <Stack sx={{ flexGrow: 1 }}>
          <ThemeProvider theme={darkTheme}>
            <AppBar position="static" color="primary">
              <Grid container spacing={10} sx={{ p: 2, pt: 1.5, pb: 1 }}>
                <Grid sx={{ p: 0 }} item xs={7}>
                  <Grid container sx={{ p: 0 }}>
                    <Grid sx={{ p: 0 }} item xs={12}>
                      <Typography sx={{ p: 0 }} component="strong">
                        <strong>Mis Registro de Maestros</strong>
                      </Typography>
                    </Grid>
                    <Grid sx={{ p: 0 }} item xs={12} />
                  </Grid>
                </Grid>

                <Grid className="search" item xs={5} textAlign="end">
                  <Grid container spacing={0} justifyContent="flex-end">
                    <Typography sx={{ p: 0 }} component="span" />
                    <Paper
                      component="form"
                      sx={{
                        p: '2px 4px', display: 'flex', alignItems: 'right', width: '100%',
                      }}
                    >
                      <>
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Buscar en usuarios"
                          inputProps={{ 'aria-label': 'search google maps' }}
                          value={buscar}
                          onChange={(e) => setBuscar(e.target.value)}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="buscar">
                          <SearchIcon />
                        </IconButton>
                      </>
                    </Paper>
                  </Grid>

                </Grid>
              </Grid>
            </AppBar>
          </ThemeProvider>
        </Stack>
        <Grid container sx={{ p: 1 }} columns={12}>
          <Grid xs={12} sm={12} md={12}>
            {masterData.data.rows && masterData.data.rows.length === 0 && (
              <Alert severity="info">
                <AlertTitle>Sin registros de maestros disponibles</AlertTitle>
                Actualmente no tienes registros disponibles para mostrar y trabajar.
                {' '}
                <strong>Es indispensable tener al menos un registro!</strong>
              </Alert>
            )}
            <List sx={{ p: 0 }}>
              { masterData.data.rows
              && masterData.data.rows.filter(
                (x) => x.name.toLowerCase().slice(0, buscar.length) === buscar.toLowerCase(),
              ).map((item) => (
                <>
                  <ListItem sx={{ p: 3 }} selected={!!(plantillaSelecionada !== null && plantillaSelecionada.id && plantillaSelecionada.id === item.id)} alignItems="flex-start">
                    <ListItemAvatar sx={{ pl: 3 }}>
                      <ArticleIcon alt="Documento" src="" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={(
                        <Typography
                          component="div"
                          color="text.primary"
                        >
                          {`${item.name}`}
                        </Typography>
                      )}
                    />
                    <Box>
                      <IconButton
                        onClick={() => handlerDetails(item)}
                        type="button"
                        sx={{ p: '10px' }}
                      >
                        <ListAltIcon />
                      </IconButton>
                    </Box>
                    <Box sx={{ p: '10px' }}>
                      <Switch
                        checked={!item.isDisabled}
                        onClick={() => handleClickChek(item)}
                        size="small"
                        disabled={update.conexion}
                      />
                    </Box>
                  </ListItem>
                  <Divider />
                </>
              ))}
            </List>
          </Grid>
        </Grid>
      </Box>
    </>

  );
}

export default Masterdata;
