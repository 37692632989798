import React from 'react';
import {
  TextField,
} from '@mui/material';
import { PropTypes } from 'prop-types';
import { checkEmail } from '../../../../../../helpers';

export function FieldEmail({
  field, onChange, indexField, indexGroup, indexFormulario, customize,
}) {
  return (
    <TextField
      fullWidth
      size="small"
      label={field.label}
      value={field.value}
      FormHelperTextProps={{
        sx: {
          color: customize.HelperText.color,
          fontSize: customize.HelperText.size,
        },
      }}
      // error={field.required && (field.value === '' || !checkEmail(field.value ? field.value : '') || field.value === null)}
      helperText={field.required && (field.value === '' || !checkEmail(field.value ? field.value : '') || field.value === null) ? '* Campo Obligatorio' : ''}
      onChange={(e) => { onChange(indexField, e.target.value, indexGroup, indexFormulario); }}
      sx={{ mt: 2 }}
      inputProps={field.length && { maxLength: Number(field.length) }}
    />
  );
}

FieldEmail.propTypes = {
  field: PropTypes.objectOf.isRequired,
  onChange: PropTypes.func.isRequired,
  indexField: PropTypes.number.isRequired,
  indexGroup: PropTypes.number.isRequired,
  indexFormulario: PropTypes.number.isRequired,
  customize: PropTypes.objectOf.isRequired,
};

export default FieldEmail;
